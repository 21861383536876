console.log('Website design and developed by Pretty Pragmatic.');

/**
 * ------------------------
 *  CONTENTS
 * ------------------------
 * 1. Mobile Navigation
 * 2. Cookies Banner
*  3. Services List (Home)
 */




// // 1. Mobile Nav Button
// //--------------------------

if (document.querySelector('#header')) {

    const ham = document.querySelector('#ham'); // input button
    const label = ham.parentElement; // label

    ham.addEventListener("click", function(){

        // Toggle class on and off to trigger CSS SVG animation.
        ham.classList.toggle('opened');

        // Set aria aria-pressed = true
        ham.setAttribute("aria-pressed", ham.classList.contains('opened'));

        label.classList.toggle('active');

        // Toggle active class to show/hide nav links.
        // mobNav.classList.toggle('active');

        // Stop the page from scrolling
        // document.body.classList.toggle('lvp-fixed');

    });


}



// 2. Cookies Popup
//--------------------------------------------


/**
 *   SERVICES LIST
 */

// Services object

const services = {
    SEO : { name: "SEO",
            description: "Boosting your organic search visibility to ensure you’re just a tap, tap, click away from new customers."
    },
    Websites : { name: "Websites",
                 description: "Transforming code and copy into an interactive experience of your brand that promises to be found and astound."
    },
    Brand : { name: "Brand",
                description: "Turning your organisation’s uniqueness into your brand – one that your customers trust implicitly and choose instinctively."
    },
    Content : { name: "Content",
                description: "Crafting content that helps you cut through the noise with wonderful words and captivating visuals."
    },
    Social : { name: "Social",
    description: "Stopping thumbs in their tracks through strategic social campaigns that give your audiences a reason to be distracted."
    },
    Analytics : { name: "Analytics",
    description: "Finding untapped opportunities to turn big data into bigger results, from skyrocketing conversion rates to ever-growing audiences."
    },
    Campaigns : { name: "Campaigns",
    description: "Turning big ideas into compelling campaigns that engage your audience and deliver on targets."
    }
};



if (document.querySelector(".pp-services__list")) {

    const li = document.querySelectorAll('[class^="pp-service--"]');
    const section = document.querySelector("#services");

    const sectionCopy = section.children[0]; // div.pp-services__copy
    const title = sectionCopy.firstElementChild; // h2
    const desc = title.nextElementSibling;  // p

    function defaultData(title, desc) {

        title.innerHTML = "Services"; // Default title.
        desc.innerHTML = "We work best when you choose a few, combining our strategy, copy, design and technology expertise to deliver great results."; // Default description.

        section.setAttribute("service", ""); // Reset attribute.
        document.querySelector(".pp-title-label--service").remove(); // Remove label.
    }

    li.forEach(el => {

        // Get the class
        const liClass = el.className;
        // The classes are prefixed with 12 charecters 'pp-service'.
        const name = liClass.substr(12);

        if (window.innerWidth > 1366) {
            // On Hover
            el.addEventListener("mouseover", function(){ highlightService(name, section); });
            // Not hovering
            el.addEventListener("mouseout", function(){ defaultData(title, desc); });


        }
   
    });

    function logKey(e) {
        // log.textContent += ` ${e.code}`;
        // console.log(e)
        highlightService(name, section);
      }

        
    // On Hover

    function highlightService(service, section) {

        // Create attribute to style background with SCSS.
        section.setAttribute("service", service);

        // Create title label on hover
        createLabel(title);


        for (const key in services) {

            if (services.hasOwnProperty(key)) {
     
                if (service === key.toLowerCase()) {
                    // Update title on hover
                    title.innerHTML = key;

                    // Update Description
                    desc.innerHTML = services[key].description;
                }
    
            }
        } 
    }

    // Create Title Label

    function createLabel(x) {

        if (!document.querySelector(".pp-title-label--service")) {

            // Create the Title Label
            const p = document.createElement("p"); // <p>
            const text = document.createTextNode("Services"); // <p>Services</p>
            p.appendChild(text);
            p.classList.add("pp-title-label--service"); // Add class
            x.insertAdjacentElement('beforebegin', p);  // Insert this p tag above the heading.
        }
    }
}



// Spotlight
// Adapted from a Code Pen example by By George W.Park.

//DOM load event
window.addEventListener("DOMContentLoaded", () => {

    const spotlight = document.querySelector('.spotlight');

    //console.log(window.innerWidth)

    // Mobile / Tablet
    if (window.innerWidth > 1366) {

        let spotlightSize = 'transparent 300px, rgba(239, 239, 239, 1) 0px';

        window.addEventListener('mousemove', e => updateSpotlight(e));

        window.addEventListener('mousedown', e => {

            // spotlightSize = 'transparent 130px, rgba(0, 0, 0, 0.95) 150px)';
            
        spotlightSize = 'transparent 160px, rgba(0, 0, 0, 0.85) 200px)';

            updateSpotlight(e);

        });

        window.addEventListener('mouseup', e => {

            // spotlightSize = 'transparent 160px, rgba(0, 0, 0, 0.85) 200px)';
            spotlightSize = 'transparent 300px, rgba(239, 239, 239, 1) 0px';

            updateSpotlight(e);

        });

        function updateSpotlight(e) {

            if(spotlight){
                spotlight.style.backgroundImage = `radial-gradient(circle at ${e.pageX / window.innerWidth * 100}% ${e.pageY / window.innerHeight * 100}%, ${spotlightSize}`;

            }
            
        }
    
    }
    else {
        spotlight.remove();
    }

});


// 6. Footer - Date
//--------------------------------------------

if (document.querySelector('#footer')) {

    // Lower Footer
    const footer = document.querySelector('.pp-footer-bottom');

    // Date
    const date = new Date().getUTCFullYear();

    footer.firstElementChild.innerHTML = "&copy; Pretty Pragmatic " + date;
}
